﻿const { reset } = require("./lib/dragscroll");
const { gridScroll } = require("./general");


var filterContainer = document.querySelector(".nopAjaxFilters7Spikes");
if (filterContainer) {
  //console.log(filterContainer.childElementCount);
  if (filterContainer.childElementCount < 1) {
    document.querySelector(".custombits-filter-mobile-btn").classList.add("hide");
  }
}

$(document).on('nopProductRibbonsLoadingCompleteEvent', function () {

  var percentage = $('.ribbon-text');
  for (var i = 0; i < percentage.length; i++) {
    if (!$(percentage[i]).hasClass("percentage")) {
      $(percentage[i]).html($(percentage[i]).html().replace('%', ''));
      $(percentage[i]).addClass("percentage")
    }
  }
  
  setItemBoxClick();

});


$(document).on('nopAjaxCartProductAddedToCartEvent', function () {
  $('#topcartlink .ico-cart').addClass('has-items');
  var text = $('.cart-qty').text().replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");;
  $('.cart-qty').text(text);

  $('.productAddedToCartWindowCheckout').click(function () {
    $('.custombits-loader').addClass('show');
  });

  setTimeout(function () {
    $('.shipping-graph-bar').width($('.shipping-graph-bar').data("size") + '%');
    $('.shipping-graph-bar').addClass('animate');
    reset();
    document.querySelectorAll('.item-grid').forEach(el => el.addEventListener('scroll', gridScroll));
  }, 200);

});

window.enableBuyButn = function () {
  document.querySelector('.nopAjaxCartProductVariantAddToCartButton').classList.remove('choose-size');
  document.querySelector('.nopAjaxCartProductVariantAddToCartButton').classList.remove('disabled');
  document.querySelector('.nopAjaxCartProductVariantAddToCartButton').value = "Kjøp";
  $('.custom-qty').removeClass('hide');
  $('.notify-me').removeClass('show');
  $('#vipps-express-checkout').removeClass('hide');
  $('.overview .custombits-klarna').removeClass('hide');
  $('.overview .custombits-shopping-info').removeClass('hide');
}

$(document).on('nopAjaxFiltersFiltrationCompleteEvent', function () {
  $(".filtersGroupPanel.filtersCheckboxPanel").hide();
  $(".toggleControl").addClass("closed");

  $('.nopAjaxFilters7Spikes').removeClass('show');

  setItemBoxClick();
  setSrcOnAsyncImage();
});

window.setItemBoxClick = function () {
  document.querySelectorAll('.item-box a').forEach(el => el.addEventListener('click', clickItemBox));

}

window.addEventListener('DOMContentLoaded', (event) => {
  setItemBoxClick();
  setSrcOnAsyncImage();
});

window.clickItemBox = function (e) {
  if (
    e.ctrlKey ||
    e.shiftKey ||
    e.metaKey || // apple
    (e.button && e.button == 1) // middle click, >IE9 + everyone else
  ) {
  } else {
    $('.custombits-loader').addClass('show');
  }
}

$(document).on("nopAjaxCartButtonsAddedEvent", function () {
  if (document.querySelector('.product-details-page') !== null) {
    addToCartBtnPresent = true;
    GetAttributesWithoutStock();
  }
});

function showNonSizeLabel() {
  var attributes = document.querySelectorAll('.attributes dl');
  for (var i = 0; i < attributes.length; i++) {
    console.log(attributes[i].querySelector('dt label').textContent.toLowerCase());
    if (attributes[i].querySelector('dt label').textContent.toLowerCase() !== 'størrelse') {
      attributes[i].querySelector('dt').style.display = 'block';
    }
  }
};
showNonSizeLabel();

function GetAttributesWithoutStock() {
  var productId = document.querySelector('.product-essential').parentElement.getAttribute('data-productid');
  var totalCountOfVariants = document.querySelectorAll('.option-list li').length;
  if (totalCountOfVariants > 0) {
    $.ajax({
      method: "GET",
      url: "/Stock/GetAttributesWithoutStock/" + productId,
      success: function (productsWithoutStock) {

        $.each(productsWithoutStock, function (i, o) {
          var productElement = $('input[value="' + o + '"]');
          $(productElement).addClass('out-of-stock');
          $(productElement).siblings('label').addClass('out-of-stock');

          if ($(productElement).siblings('label').html() === "") { // hvis størrelse ikke har navn
            $(productElement).siblings('label').addClass('no-name');
          }

          $(productElement).click(function () {
            document.querySelector('.nopAjaxCartProductVariantAddToCartButton').classList.add('disabled');
            document.querySelector('.nopAjaxCartProductVariantAddToCartButton').value = "Utsolgt";
            $('.notify-me').addClass('show');
            $('.custom-qty').addClass('hide');
            $('#vipps-express-checkout').addClass('hide');
            $('.overview .custombits-klarna').addClass('hide');
            $('.overview .custombits-shopping-info').addClass('hide');
          });
        });

        if (productsWithoutStock.length === totalCountOfVariants) {
          document.querySelector('.nopAjaxCartProductVariantAddToCartButton').classList.add('disabled');
          document.querySelector('.nopAjaxCartProductVariantAddToCartButton').value = "Utsolgt";
          $('.custom-qty').addClass('hide');
          $('.notify-me').addClass('show');
          $('.overview .custombits-klarna').addClass('hide');
          $('.overview .custombits-shopping-info').addClass('hide');

          if (totalCountOfVariants === 1) {
            var productElement = $('.attributes .option-list li input');
            productElement.click();
            productElement.prop('checked', true);



            setTimeout(function () {
              var productElement = $('.attributes .option-list li input');
              productElement.prop('checked', true);
            }, 200);

            setTimeout(function () {
              var productElement2 = $('.attributes .option-list li input');
              productElement2.click();
            }, 300);
          }

        } else {

          if (totalCountOfVariants === 1) {
            setTimeout(function () {
              var productElement = $('.attributes .option-list li input');
              productElement.prop('checked', true);
            }, 200);

            setTimeout(function () {
              var productElement2 = $('.attributes .option-list li input');
              productElement2.click();
            }, 300);

            if ($(productElement).siblings('label').html() === "") {
              $(productElement).siblings('label').addClass('no-name');
            }

          } else {
            var requiredAttributes = document.querySelectorAll('.attributes dl dt span.required').length;
            if (requiredAttributes) {
              document.querySelector('.nopAjaxCartProductVariantAddToCartButton').classList.add('choose-size');
              var labelTextOfRequiredElement = document.querySelector('.attributes dl dt span.required').parentElement.parentElement.querySelector('dt label').textContent;
              document.querySelector('.nopAjaxCartProductVariantAddToCartButton').value = "Velg " + labelTextOfRequiredElement.toLowerCase() + " først";
            }

            var sizeInputs = document.querySelectorAll('.option-list li input:not(.out-of-stock)');
            sizeInputs.forEach(el => el.addEventListener('click', enableBuyButn));
          }
        }

        document.querySelector('.loader').classList.add('done');

      },
      error: function (request, status, error) {
      }
    });
  }
  else {
    $.ajax({
      method: "GET",
      url: "/Stock/GetStockForProduct/" + productId,
      success: function (data) {
        var productType = data.type;
        var productStock = data.stock;

        if (productStock < 1 && productType === 'Product') {
          document.querySelector('.nopAjaxCartProductVariantAddToCartButton').classList.add('disabled');
          document.querySelector('.nopAjaxCartProductVariantAddToCartButton').value = "Utsolgt";
          $('.notify-me').addClass('show');
          $('.custom-qty').addClass('hide');
          $('.overview .custombits-klarna').addClass('hide');
          $('.overview .custombits-shopping-info').addClass('hide');
        }

        document.querySelector('.loader').classList.add('done');
      },
      error: function (request, status, error) {
      }
    });

  }
}